var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "ele-bwc-show" },
    [
      _c(
        "el-form",
        {
          staticStyle: { "margin-bottom": "15px" },
          attrs: { inline: true, model: _vm.filterForm }
        },
        [
          _c(
            "el-form-item",
            { attrs: { label: "专场名称" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.filterForm.name,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "name", $$v)
                  },
                  expression: "filterForm.name"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "专场定位" } },
            [
              _c("el-input", {
                attrs: { clearable: "" },
                model: {
                  value: _vm.filterForm.address,
                  callback: function($$v) {
                    _vm.$set(_vm.filterForm, "address", $$v)
                  },
                  expression: "filterForm.address"
                }
              })
            ],
            1
          ),
          _c(
            "el-form-item",
            { attrs: { label: "是否存在售罄店铺" } },
            [
              _c(
                "el-select",
                {
                  model: {
                    value: _vm.filterForm.exist_sell_out,
                    callback: function($$v) {
                      _vm.$set(_vm.filterForm, "exist_sell_out", $$v)
                    },
                    expression: "filterForm.exist_sell_out"
                  }
                },
                [
                  _c("el-option", { attrs: { label: "全部", value: 0 } }),
                  _c("el-option", { attrs: { label: "是", value: true } }),
                  _c("el-option", { attrs: { label: "否", value: false } })
                ],
                1
              )
            ],
            1
          ),
          _c(
            "el-form-item",
            [
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.onFilter } },
                [_vm._v("查询")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.positionDialog = true
                    }
                  }
                },
                [_vm._v("新增专场")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "el-table",
        { staticStyle: { width: "100%" }, attrs: { data: _vm.list } },
        [
          _c("el-table-column", {
            attrs: { label: "ID", prop: "id", width: "75px" }
          }),
          _c("el-table-column", {
            attrs: { label: "商家专场名称", prop: "name" }
          }),
          _c("el-table-column", {
            attrs: { label: "专场定位", prop: "address" }
          }),
          _c("el-table-column", {
            attrs: { label: "是否存在售罄店铺" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    row.exit_sell_out
                      ? _c("el-tag", { attrs: { type: "danger" } }, [
                          _vm._v("是")
                        ])
                      : _c("el-tag", { attrs: { type: "success" } }, [
                          _vm._v("否")
                        ])
                  ]
                }
              }
            ])
          }),
          _c("el-table-column", {
            attrs: { label: "更新时间", prop: "updated_at" }
          }),
          _c("el-table-column", {
            attrs: { label: "操作" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(ref) {
                  var row = ref.row
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.onPromote(row)
                          }
                        }
                      },
                      [_vm._v("取链")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.onDelete(row)
                          }
                        }
                      },
                      [_vm._v("删除")]
                    ),
                    _c(
                      "el-button",
                      {
                        attrs: { type: "text", size: "mini" },
                        on: {
                          click: function($event) {
                            return _vm.onEdit(row)
                          }
                        }
                      },
                      [_vm._v("修改")]
                    )
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "div",
        { staticStyle: { "text-align": "center" } },
        [
          _c("el-pagination", {
            attrs: {
              background: "",
              "current-page": _vm.filterForm.page,
              "page-sizes": [10, 20, 50, 100],
              "page-size": _vm.filterForm.page_size,
              layout: "total, sizes, prev, pager, next, jumper",
              total: _vm.listTotal
            },
            on: {
              "update:currentPage": function($event) {
                return _vm.$set(_vm.filterForm, "page", $event)
              },
              "update:current-page": function($event) {
                return _vm.$set(_vm.filterForm, "page", $event)
              },
              "update:pageSize": function($event) {
                return _vm.$set(_vm.filterForm, "page_size", $event)
              },
              "update:page-size": function($event) {
                return _vm.$set(_vm.filterForm, "page_size", $event)
              },
              "current-change": _vm.getData,
              "size-change": _vm.onFilter
            }
          })
        ],
        1
      ),
      _c(
        "el-dialog",
        {
          attrs: { visible: _vm.positionDialog, width: "80%" },
          on: {
            "update:visible": function($event) {
              _vm.positionDialog = $event
            }
          }
        },
        [
          _c(
            "div",
            { staticClass: "d-flex align-center" },
            [
              _c("span", [_vm._v("请选择店铺区域：")]),
              _c("span", [_vm._v("省")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择省" },
                  model: {
                    value: _vm.provinceId,
                    callback: function($$v) {
                      _vm.provinceId = $$v
                    },
                    expression: "provinceId"
                  }
                },
                _vm._l(_vm.regionData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              ),
              _c("span", [_vm._v("市")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择市" },
                  model: {
                    value: _vm.cityId,
                    callback: function($$v) {
                      _vm.cityId = $$v
                    },
                    expression: "cityId"
                  }
                },
                _vm._l(_vm.cityData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              ),
              _c("span", [_vm._v("区")]),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择区" },
                  model: {
                    value: _vm.areaId,
                    callback: function($$v) {
                      _vm.areaId = $$v
                    },
                    expression: "areaId"
                  }
                },
                _vm._l(_vm.areaData, function(item) {
                  return _c("el-option", {
                    key: item.id,
                    attrs: { label: item.name, value: item.id }
                  })
                }),
                1
              ),
              _c("span", [_vm._v("具体定位：")]),
              _c("span", { staticStyle: { "margin-right": "20px" } }, [
                _vm._v(_vm._s(_vm.position.address || "请在下方选择"))
              ]),
              _c(
                "p",
                {
                  staticStyle: {
                    margin: "15px 0",
                    "font-size": "12px",
                    color: "#999"
                  }
                },
                [_vm._v("提示：选择成功后不将不可修改，请仔细核对！")]
              )
            ],
            1
          ),
          _c("iframe", {
            staticStyle: { width: "100%", height: "440px" },
            attrs: { src: _vm.mapUrl, frameborder: "0" }
          }),
          _c(
            "div",
            { staticStyle: { "text-align": "center", "padding-top": "10px" } },
            [
              _c(
                "el-button",
                {
                  staticStyle: { width: "120px" },
                  attrs: { type: "primary", size: "medium" },
                  on: { click: _vm.confirmAddress }
                },
                [_vm._v("确定")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.promoteDialog,
            title: "取链",
            center: "",
            width: "500px"
          },
          on: {
            "update:visible": function($event) {
              _vm.promoteDialog = $event
            }
          }
        },
        [
          !_vm.promoteData.click_url
            ? _c(
                "div",
                [
                  _c(
                    "el-form",
                    { attrs: { "label-width": "100px" } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "推广方式：" } },
                        [
                          _c(
                            "el-radio-group",
                            {
                              model: {
                                value: _vm.promoteData.type,
                                callback: function($$v) {
                                  _vm.$set(_vm.promoteData, "type", $$v)
                                },
                                expression: "promoteData.type"
                              }
                            },
                            [
                              _c("el-radio", { attrs: { label: 1 } }, [
                                _vm._v("saas")
                              ]),
                              _c("el-radio", { attrs: { label: 2 } }, [
                                _vm._v("联盟")
                              ])
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "邀请码：" } },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.promoteData.code,
                              callback: function($$v) {
                                _vm.$set(_vm.promoteData, "code", $$v)
                              },
                              expression: "promoteData.code"
                            }
                          })
                        ],
                        1
                      ),
                      _c(
                        "el-form-item",
                        { attrs: { label: "" } },
                        [
                          _c(
                            "el-button",
                            {
                              staticStyle: { width: "90px" },
                              attrs: {
                                loading: _vm.linkFetching,
                                type: "primary"
                              },
                              on: { click: _vm.getLink }
                            },
                            [_vm._v("确定")]
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            : _c(
                "div",
                [
                  _c(
                    "p",
                    { staticStyle: { color: "#222", "margin-bottom": "10px" } },
                    [
                      _c("el-tag", { attrs: { type: "primary" } }, [
                        _vm._v("H5")
                      ]),
                      _vm._v(" "),
                      _c("small", { staticStyle: { color: "#999" } }, [
                        _vm._v("右键图片（二维码）可保存")
                      ])
                    ],
                    1
                  ),
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: {
                      src: _vm.promoteData.click_url_qrcode,
                      fit: "contain"
                    }
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "flex-1",
                          staticStyle: { "word-break": "break-all" }
                        },
                        [_vm._v(_vm._s(_vm.promoteData.click_url))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.copy(_vm.promoteData.click_url, $event)
                            }
                          }
                        },
                        [_vm._v("复制")]
                      )
                    ],
                    1
                  ),
                  _c("el-divider"),
                  _c(
                    "p",
                    { staticStyle: { color: "#222", "margin-bottom": "10px" } },
                    [
                      _c("el-tag", { attrs: { type: "primary" } }, [
                        _vm._v("小程序")
                      ])
                    ],
                    1
                  ),
                  _c("el-image", {
                    staticStyle: { width: "100px", height: "100px" },
                    attrs: { src: _vm.promoteData.mp_qrcode, fit: "contain" }
                  }),
                  _c(
                    "div",
                    { staticClass: "d-flex align-center" },
                    [
                      _c(
                        "p",
                        {
                          staticClass: "flex-1",
                          staticStyle: { "word-break": "break-all" }
                        },
                        [_vm._v(_vm._s(_vm.promoteData.mp_path))]
                      ),
                      _c(
                        "el-button",
                        {
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.copy(_vm.promoteData.mp_path, $event)
                            }
                          }
                        },
                        [_vm._v("复制")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }